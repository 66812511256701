.container {
  background-color: #f26915;
  color: #ffffff;
  text-align: center;
  min-height: 324px;
  & .content {
    padding: 56px 0 126px;
  }
  .title {
    font-family: "sunset-gothic";
    font-size: 33px;
    font-weight: 700;
    line-height: 39.94px;
    letter-spacing: -0.02em;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #fff;
    padding: 0;
    margin: 0px 0 73px;
    @media (max-width: 767px) {
      font-size: 28px;
      margin: 0px 0 48px;
     }
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr); 
    gap: 20px;
    row-gap: 57px;
    justify-items: center;
    align-items: center;
    max-width: calc(1116px + 40px);
    margin: 0 auto;
    padding: 0 20px;
    @media (max-width: 1023px) {
      grid-template-columns: repeat(3, 1fr); 
    }
    @media (max-width: 767px) {
      grid-template-columns: repeat(2, 1fr); 
    }
  }

  .item {
    display: flex;
    align-items: center;
    gap: 24px;
    font-size: 14px;
    width: 100%;
    @media (max-width: 767px) {
      gap: 12px;
    }
  }

  .circle {
    width: 38px;
    height: 38px;
    min-width: 38px;
    background-color: #f97c30;
    color: #fff;
    font-family: "sunset-gothic";
    font-size: 17px;
    font-weight: 700;
    line-height: 25.5px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & .itemText {
    font-family: "sunset-gothic";
    font-size: 19px;
    font-weight: 400;
    line-height: 28.5px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #ffffff;
    @media (max-width: 1200px) {
      font-size: 16px;
      line-height: 20px;
    }
    @media (max-width: 767px) {
      font-size: 14px;
      line-height: 20px;
    }
  }
}
